import React, { useState, useEffect } from "react";
import "./App.css";
import { FaReact, FaHtml5, FaCss3Alt, FaJsSquare, FaJava } from "react-icons/fa"; 
import { SiAngular } from "react-icons/si"; 
import Home from "./page/Home"; 
import Portafolio from "./page/Portafolio"; 
import Skills from "./page/Skills"; 
import Contacto from "./page/Contacto"; 
import Experiencia from "./page/Experiencia"; 
import Navbar from "./components/Navbar"; // Importamos el componente Navbar

import pcImage from "./assets/pc.webp"; 

const App = () => {
  const [currentSection, setCurrentSection] = useState("Home");
  const [loading, setLoading] = useState(true);

  const sections = ["Home", "Portafolio", "Skills", "Experiencia", "Contacto"];

  const handleChangeSection = (section) => {
    setCurrentSection(section);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app-container">
      
        <div className="sections-container">
          {currentSection === "Home" && <Home />}
          {currentSection === "Portafolio" && <Portafolio />}
          {currentSection === "Skills" && <Skills />}
          {currentSection === "Experiencia" && <Experiencia />}
          {currentSection === "Contacto" && <Contacto />}
        </div>
      

      {/* Añadimos el Navbar para manejar la navegación */}
      <Navbar onSectionChange={handleChangeSection} currentSection={currentSection} sections={sections} />

      {/* Imagen de fondo */}
      <div className="image-container">
        <img src={pcImage} alt="PC con código" className="pc-image" />
      </div>

      {/* Iconos flotantes */}
      <i className="icon react"><FaReact /></i>
      <i className="icon html"><FaHtml5 /></i>
      <i className="icon css"><FaCss3Alt /></i>
      <i className="icon js"><FaJsSquare /></i>
      <i className="icon java"><FaJava /></i>
      <i className="icon angular"><SiAngular /></i>
    </div>
  );
};

export default App;
