// Skills.js
import React from "react";
import { FaReact, FaHtml5, FaCss3Alt, FaJsSquare, FaNodeJs } from "react-icons/fa";
import { SiPython, SiPhp } from "react-icons/si";
import "./Skills.css";

const skills = [
  { name: "React", icon: <FaReact />, description: "JavaScript Library for UI" },
  { name: "HTML", icon: <FaHtml5 />, description: "Markup Language" },
  { name: "CSS", icon: <FaCss3Alt />, description: "Style Sheets" },
  { name: "JavaScript", icon: <FaJsSquare />, description: "Programming Language" },
  { name: "Node.js", icon: <FaNodeJs />, description: "JavaScript Runtime" },
  { name: "Python", icon: <SiPython />, description: "Popular Scripting Language" },
  { name: "PHP", icon: <SiPhp />, description: "Server-Side Scripting" },
];

const Skills = () => {
  return (
    <div className="skills">
      <h2 className="skills-title">Mis <span>Skills</span></h2>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <div key={index} className="skill-card">
            <div className="skill-icon">{skill.icon}</div>
            <h3>{skill.name}</h3>
            <p>{skill.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
