import React from "react";
import "./Experiencia.css";

const Experiencia = () => {
  return (
    <div className="experiencia-container">
      <h2 className="title">Experiencia y <span className="text">Estudios</span></h2>

      <div className="section-container">
        {/* Contenedor de Experiencia */}
        <div className="experience-container">
          <h3>Experiencia</h3>
          <ul className="experience-list">
            <li className="experience-item">
              <h4>Gabres <span>2017-2023</span></h4>
              <ul>
                <li>Encargado de fábrica</li>
                <li>Manejo de herramientas</li>
                <li>Oficial de primera</li>
              </ul>
            </li>
            <li className="experience-item">
              <h4>Desarrollo web para abogado <span>2022</span></h4>
              <ul>
                <li><p>Creación de una página web con React, adaptada a las necesidades de un abogado, enfocada en funcionalidad y diseño profesional.</p></li>
              </ul>
            </li>
            <li className="experience-item">
              <h4>Navagatel <span>Actualidad</span></h4>
              <ul>
                <li>Maquetación Wordpress / Elementor</li>
                <li>Código HTML - CSS - JAVASCRIPT</li>
                <li>Kit Digital</li>
              </ul>
            </li>
            <li className="experience-item">
              <h4>Desarrollo de aplicación para gestión de citas <span>2024</span></h4>
              <ul>
                <li>              
                    <p>Plataforma web para gestionar citas en una peluquería utilizando PHP, MySQL y Bootstrap. Proyecto freelance realizado sin contrato formal, brindando solución práctica.</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {/* Contenedor de Estudios */}
        <div className="studies-container">
          <h3>Estudios</h3>
          <ul className="studies-list">
            <li className="studies-item">
              <h4>Estudios autodidactas <span>2019-2020</span></h4>
              <ul>
                <li>    
                    <p>Estudios realizados de manera autónoma, con enfoque en desarrollo web frontend, tecnologías como HTML, CSS y JavaScript.</p>
                </li>
              </ul>
            </li>
            <li className="studies-item">
              <h4>Euroinnova Formación <span>2020</span></h4>
              <ul>
                <li><p>Curso especializado en frontend, profundizando en tecnologías como JavaScript, CSS avanzado y frameworks de desarrollo web.</p></li>
              </ul>
              
            </li>
            <li className="studies-item">
              <h4>Cursos Udemy <span>2021-2023</span></h4>
              <ul>
                <li><p>Cursos en desarrollo frontend, JavaScript y Python, mejorando habilidades técnicas y prácticas con proyectos reales.</p></li>
              </ul>
            </li>
            <li className="studies-item">
              <h4>Master-D <span>2023-2024</span></h4>
              <ul>
                <li><p>Curso superior de creación de páginas web, con especialización en diseño, desarrollo y maquetación de sitios web, aprobado con nota.</p></li>
              </ul>
            </li>
            <li className="studies-item">
              <h4>CEAC <span>2024</span></h4>
              <ul>
                <li><p>Cursando actualmente un curso superior de Desarrollo de Aplicaciones Web (DAW), centrado en tecnologías de backend, bases de datos y arquitectura web.</p></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Experiencia;
