import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Importa el archivo App principal
import "./index.css"; // Estilo global, incluyendo Tailwind si lo usas



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
