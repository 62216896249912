import React from "react";
import "./Navbar.css";

const Navbar = ({ onSectionChange, currentSection, sections }) => {
  const handleNavigation = (direction) => {
    const currentIndex = sections.indexOf(currentSection);
    let nextIndex = currentIndex + direction;

    if (nextIndex >= sections.length) nextIndex = 0; // Si se pasa del último, vuelve al primero
    if (nextIndex < 0) nextIndex = sections.length - 1; // Si se pasa del primero, va al último

    onSectionChange(sections[nextIndex]);
  };

  return (
    <div className="navbar">
      {/* Botón izquierdo */}
      <button
        className="nav-button left"
        onClick={() => handleNavigation(-1)} // Mover a la sección anterior
      >
        &lt;
      </button>
      {/* Botón derecho */}
      <button
        className="nav-button right"
        onClick={() => handleNavigation(1)} // Mover a la siguiente sección
      >
        &gt;
      </button>
    </div>
  );
};

export default Navbar;
