import React, { useState } from "react";
import "./Portafolio.css";
import FariniaVideo from "../assets/farinia.mp4";
import PeluqueroVideo from "../assets/peluquero.mp4";
import GymVideo from "../assets/gym.mp4";
import Abogada from "../assets/Abogada.mp4";

const projects = [
  {
    id: 1,
    title: "Hosteleria",
    description:
      "Permite gestionar reservas de mesas, agregar productos al menú y controlar la contabilidad del establecimiento. Los usuarios pueden hacer reservas en tiempo real y ver el menú digitalmente, mientras que los administradores pueden gestionar productos, categorías y precios. Además, la aplicación lleva un registro de ventas, ingresos y gastos, proporcionando informes detallados. Está desarrollada con PHP, HTML, CSS y JavaScript, y utiliza una base de datos para almacenar la información de reservas, productos y transacciones.",
    video: FariniaVideo,
  },
  {
    id: 2,
    title: "Reservas",
    description:
      "La aplicación de peluquería permite gestionar citas, agregar servicios y llevar un control de la facturación. Los usuarios pueden reservar citas en línea y elegir entre los servicios disponibles, mientras que los administradores pueden agregar, modificar o eliminar servicios y gestionar pagos. La aplicación también ofrece un seguimiento de ingresos y gastos. Desarrollada con PHP, HTML, CSS y JavaScript, utiliza una base de datos para almacenar citas, servicios y transacciones.",
    video: PeluqueroVideo,
  },
  {
    id: 3,
    title: "Gym",
    description:
      "La aplicación web de gimnasio, desarrollada con React, permite a los usuarios consultar y reservar clases, ver planes de entrenamiento y seguir su progreso. Los administradores pueden gestionar las clases, añadir nuevos entrenamientos y hacer un seguimiento de los usuarios. La aplicación incluye funcionalidades de registro, inicio de sesión y perfiles personalizados. Utiliza una base de datos para almacenar usuarios, clases y registros de progreso, brindando una experiencia interactiva y dinámica.",
    video: GymVideo,
  },
  {
    id: 4,
    title: "Abogada",
    description:
      "La aplicación web para abogados, desarrollada con React, permite a los usuarios consultar servicios legales, agendar citas y acceder a su historial de casos. Los abogados pueden gestionar su agenda, revisar casos y añadir documentos relacionados. La plataforma incluye funcionalidades de registro e inicio de sesión, además de perfiles personalizados para cada usuario. Utiliza una base de datos para almacenar información de clientes, casos y citas, ofreciendo una experiencia eficiente y accesible.",
    video: Abogada,
  },
];

const Portafolio = () => {
  const [selectedProject, setSelectedProject] = useState(0); // Proyecto seleccionado

  return (
    <div className="portafolio">
      <h1 className="portafolio-title">Mis <span className="span">Proyectos</span></h1>
      <div className="portafolio-buttons">
        {projects.map((project, index) => (
          <button
            key={project.id}
            className={`portafolio-button ${
              selectedProject === index ? "active" : ""
            }`}
            onClick={() => setSelectedProject(index)}
          >
            {project.title}
          </button>
        ))}
      </div>

      <div className="portafolio-content">
        {/* Agregamos la lógica para alternar entre proyectos */}
        <div className="portafolio-text">
          <h2>{projects[selectedProject].title}</h2>
          <hr />
          <p>{projects[selectedProject].description}</p>
        </div>
        <div className="portafolio-video">
          <video
            src={projects[selectedProject].video}
            controls
            autoPlay
            loop
            muted
            className="project-video"
          />
        </div>
      </div>
    </div>
  );
};

export default Portafolio;
